import React from "react"

import Layout from "../components/layout"
import ProjectNav from "../components/project-nav"
import Seo from "../components/seo"
import mockup from "../images/uri/mockup.png"

import mockup2 from "../images/uri/mockup2.png"
import mobile1 from "../images/uri/mobile1.png"
import mobile2 from "../images/uri/mobile2.png"
import mobile3 from "../images/uri/mobile3.png"
import logos from "../images/uri/logos.png"
import moodboard from "../images/uri/moodboard.png"

const SecondPage = () => (
  <Layout>
    <Seo title="uri" />
    <div className="light-grey">
      <ProjectNav
        className="small-space-top"
        previous="/quinova"
        next="/marketing-monkeys"
      />
      <div className="content grid space smaller-space-top">
        <div className="col-1">
          <h2>Electronic Coordination Platform</h2>
          <p className="teaser-text">
            In this project, both the logo and the landing page for the
            electronic coordination platform "URec" were created in close
            colaboration with a software developer.
          </p>
          <p>
            <a
              href="https://camac.ur.ch/"
              className="button"
              target="_blank"
              rel="noreferrer"
            >
              View Website
            </a>
          </p>
        </div>
        <img
          className="col-2"
          src={mockup}
          alt="Quinova Website on Desktop, Laptop and Mobile"
        />
      </div>
    </div>
    <div className="content">
      <div className="grid space">
        <img className="col-2" src={mockup2} alt="mockup for desktop" />
        <div className="col-1">
          <h3>The URec Platform</h3>
          <p>
            URec offers multiple different log in mechanisms. Using a full size
            background image, the landing page should represent the Canton of
            Uri. At the same time three large buttons guide the users in an
            obvious way to the correct login mechanism.
          </p>
        </div>
      </div>

      <div className="grid space" style={{ gridGap: "9rem" }}>
        <img className="col-1" src={mobile1} alt="login page for mobile" />
        <img className="col-1" src={mobile2} alt="login page form for mobile" />
        <img className="col-1" src={mobile3} alt="registration page" />
      </div>

      <div className="grid space">
        <div className="col-1">
          <h3>Moodboard</h3>
        </div>
        <img className="col-2 shadow" src={moodboard} alt="moodboard" />
      </div>

      <div className="grid space-below">
        <div className="col-1">
          <h3>Logo Design</h3>
          <p>
            The URec logo should reflect the colaborative character of the
            platform. The design in font, color and form goes in line with the{" "}
            <a href="https://ur.ch/" target="_blank" rel="noreferrer">
              website
            </a>{" "}
            and logo of the Canton of Uri.
          </p>
        </div>
        <img className="col-2 shadow" src={logos} alt="URec logos" />
      </div>
    </div>
    <ProjectNav
      className="space-below"
      previous="/quinova"
      next="/marketing-monkeys"
    />
  </Layout>
)

export default SecondPage
